<template>
  <v-card elevation="0">
    <v-card-title class="d-flex align-center mt-5 mb-5">
      <p class="text-body-1 ma-0 font-weight-bold mr-8">Datos generales</p>
      <v-icon color="#A3A0FB">mdi-account-circle-outline</v-icon>
      <v-btn color="#A3A0FB" elevation="0" small rounded class="text-none caption ml-2 mr-3">{{ getAllCandidates.length }}
        aplicaciones</v-btn>
      <v-btn small class="text-none caption" rounded outlined color="#8739EB" elevation="0" v-if="!editing"
        @click="editing = !editing">
        <v-icon class="mr-1" small color="#8739EB">mdi-pencil-outline</v-icon>
        Editar vacante
      </v-btn>
      <v-btn small class="text-none caption mr-3" rounded outlined color="#8739EB" elevation="0" v-if="editing"
        @click="logUpdating()">
        <v-icon class="mr-1" small color="#8739EB">mdi-content-save-all-outline</v-icon>
        Guardar cambios
      </v-btn>
      <v-btn small class="text-none caption" rounded outlined color="#8739EB" elevation="0" v-if="editing"
        @click="logUpdating()">
        <v-icon class="mr-1" small color="#8739EB">mdi-close-circle</v-icon>
        Cancelar
      </v-btn>
    </v-card-title>

    <v-alert dense text type="success" transition="scale-transition" v-if="editedVacancy">
      <p class="ma-0 pa-0 caption">¡Datos modificados con éxito!</p>
    </v-alert>
    <v-card elevation="0" class="mb-16">
      <v-divider></v-divider>
      <v-container class="ma-0 pa-0" fluid grid-list-md>
        <v-layout row wrap d-flex justify-start>
          <v-flex d-flex justify-start xs12 sm12 md6>
            <v-card class="d-flex flex-column" width="95%" elevation="0">
              <div class="d-flex align-center mt-5 mb-5">
                <div class="d-flex align-center" style="width: 50%">
                  <v-icon color="#8739EB" small>mdi-briefcase-outline</v-icon>
                  <p class="caption ma-0 font-weight-bold ml-6 mr-16">Nombre</p>
                </div>
                <v-text-field :readonly="!editing" v-model="vacancyName"
                  class="d-flex text-left ma-0 font-weight-light mr-8 mt-n4 py-5" color="#8739EB" dense rounded
                  :filled="editing" :counter="20" :rules="[(v) => !!(v && v.length <= 20) || 'Este campo es requerido']">
                </v-text-field>
              </div>
              <v-divider></v-divider>
              <div class="d-flex align-center mt-5 mb-5">
                <div class="d-flex align-center" style="width: 50%">
                  <v-icon class="icon-input" color="#8739EB" small>
                    mdi-card-text-outline
                  </v-icon>
                  <p class="caption ma-0 font-weight-bold ml-6 mr-16">
                    Descripción
                  </p>
                </div>

                <div v-if="editing" style="width: 100%;">
                  <v-text-field :readonly="!editing" :counter="3950" v-model="vacancyDescription"
                    :rules="[(v) => !!(v && v.length <= 3950) || 'Este campo es requerido']"
                    class="d-flex ma-0 text-left mr-8 mt-n4 py-10" style="width: 100;" color="#8739EB" :filled="editing">
                  </v-text-field>
                </div>
                <div v-else style="display: flex;justify-content: end;">
                  <div style="width: 80%;" class="mb-n4 d-flex  caption ma-0 font-weight-light text-justify">{{ vacancyDescription }}</div>
                </div>



              </div>
              <v-divider></v-divider>
              <div class="d-flex align-center mt-5 mb-5">
                <div class="d-flex align-center" style="width: 50%">
                  <v-icon class="icon-input" color="#8739EB" small>
                    mdi-briefcase-clock-outline
                  </v-icon>
                  <p class="caption ma-0 font-weight-bold ml-6 mr-16">
                    Jornada laboral
                  </p>
                </div>
                <v-text-field :readonly="!editing" v-model="vacancyJobHours"
                  :rules="[(v) => !!v || 'Este campo es requerido']"
                  class="d-flex ma-0 text-left font-weight-light mr-8 mt-n4 body-1 py-6" color="#8739EB" dense rounded
                  :filled="editing">
                </v-text-field>
              </div>
              <v-divider></v-divider>
              <div class="d-flex align-center mt-5 mb-5">
                <div class="d-flex align-center" style="width: 50%">
                  <v-icon color="#8739EB" small>mdi-numeric</v-icon>
                  <p class="caption ma-0 text-left font-weight-bold ml-6 mr-16">
                    Vacantes disponibles
                  </p>
                </div>
                <v-text-field :readonly="!editing" v-model="avaliableVacancies"
                  :rules="[(v) => !!v || 'Este campo es requerido']"
                  class="d-flex text-left ma-0 font-weight-light mr-8 mt-n4 py-6" color="#8739EB" dense rounded
                  :filled="editing">
                </v-text-field>
              </div>
              <v-divider></v-divider>
              <div class="d-flex align-center mt-5 mb-5">
                <div class="d-flex align-center" style="width: 50%">
                  <v-icon color="#8739EB" small>mdi-briefcase-eye-outline</v-icon>
                  <p class="caption ma-0 font-weight-bold text-left ml-6 mr-16 py-6">
                    Área de la compañia
                  </p>
                </div>
                <v-text-field :readonly="!editing" v-model="companyArea"
                  :rules="[(v) => !!v || 'Este campo es requerido']"
                  class="d-flex text-left ma-0 font-weight-light mr-8 mt-n4" color="#8739EB" dense rounded
                  :filled="editing">
                </v-text-field>
              </div>
              <v-divider></v-divider>
              <div class="d-flex align-center mt-5 mb-5">
                <div class="d-flex align-center" style="width: 50%">
                  <v-icon color="#8739EB" small>mdi-calendar-start</v-icon>
                  <p class="caption ma-0 font-weight-bold text-left ml-6 mr-16">
                    Fecha de inicio
                  </p>
                </div>
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field :rules="[(v) => !!v || 'Este campo es requerido']" :readonly="!editing"
                      class="d-flex ma-0 text-left font-weight-light mr-8 mt-n4 body-1" color="#8739EB"
                      :value="formattedDateStarts" dense rounded :filled="editing" v-bind="attrs" v-on="on">
                    </v-text-field>
                  </template>
                  <v-date-picker v-model="vacancyStartsAt" color="#8739EB" :disabled="!editing"></v-date-picker>
                </v-menu>
              </div>
              <v-divider></v-divider>
              <div class="d-flex align-center mt-5 mb-5">
                <div class="d-flex align-center" style="width: 50%">
                  <v-icon color="#8739EB" small>mdi-calendar-end</v-icon>
                  <p class="caption ma-0 font-weight-bold text-left ml-6 mr-16">
                    Fecha de expiración
                  </p>
                </div>
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field :readonly="!editing" :rules="[(v) => !!v || 'Este campo es requerido']"
                      class="d-flex ma-0 text-left font-weight-light mr-8 mt-n4 body-1" color="#8739EB"
                      :value="formattedDateEnds" dense rounded :filled="editing" v-bind="attrs" v-on="on">
                    </v-text-field>
                  </template>
                  <v-date-picker v-model="vacancyExpiresAt" color="#8739EB" :disabled="!editing"></v-date-picker>
                </v-menu>
              </div>
              <v-divider></v-divider>
              <div class="d-flex align-center mt-5 mb-5">
                <div class="d-flex align-center" style="width: 50%">
                  <v-icon color="#8739EB" small>mdi-numeric</v-icon>
                  <p class="caption ma-0 font-weight-bold text-left ml-6 mr-16">
                    Máximo de candidatos
                  </p>
                </div>
                <v-text-field :rules="[(v) => !!v || 'Este campo es requerido']" :readonly="!editing"
                  v-model="vacancyMaximumCandidates"
                  class="d-flex ma-0 text-left font-weight-light mr-8 mt-n4 body-1 py-6" color="#8739EB" dense rounded
                  :filled="editing">
                </v-text-field>
              </div>
              <v-divider></v-divider>
              <div class="d-flex align-center mt-5 mb-5">
                <div class="d-flex align-start mt-5" style="height: 100%; width: 35%">
                  <v-icon color="#8739EB" small>mdi-wind-turbine-check</v-icon>
                  <p class="caption ma-0 font-weight-bold ml-6 mr-16">
                    Disponibilidad
                  </p>
                </div>
                <div class="d-flex justify-space-between align-center pt-12" style="width: 100%">
                  <div class="d-flex flex-column">
                    <v-simple-table>
                      <template v-slot:default>
                        <tbody>
                          <tr v-for="(day, index) in week" :key="index">
                            <td class="caption font-weight-bold text-left">
                              {{ day.text }}
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </div>

                  <div class="d-flex flex-column align-start" style="width: 100%">
                    <v-simple-table>
                      <template v-slot:default>
                        <tbody>
                          <tr class="d-flex flex-column">
                            <td class="caption text-left d-flex align-center justify-center"
                              style="border-bottom: 1px solid #11203a29">
                              {{
                                getVacancyData.vacancy_schedule.Mon.length === 0
                                ? "-"
                                : getVacancyData.vacancy_schedule.Mon[0] +
                                " hrs"
                              }}
                            </td>

                            <td class="caption text-left d-flex align-center justify-center"
                              style="border-bottom: 1px solid #11203a29">
                              {{
                                getVacancyData.vacancy_schedule.Tue.length === 0
                                ? "-"
                                : getVacancyData.vacancy_schedule.Tue[0] +
                                " hrs"
                              }}
                            </td>

                            <td class="caption text-left d-flex align-center justify-center"
                              style="border-bottom: 1px solid #11203a29">
                              {{
                                getVacancyData.vacancy_schedule.Wed.length === 0
                                ? "-"
                                : getVacancyData.vacancy_schedule.Wed[0] +
                                " hrs"
                              }}
                            </td>

                            <td class="caption text-left d-flex align-center justify-center"
                              style="border-bottom: 1px solid #11203a29">
                              {{
                                getVacancyData.vacancy_schedule.Thu.length === 0
                                ? "-"
                                : getVacancyData.vacancy_schedule.Thu[0] +
                                " hrs"
                              }}
                            </td>

                            <td class="caption text-left d-flex align-center justify-center"
                              style="border-bottom: 1px solid #11203a29">
                              {{
                                getVacancyData.vacancy_schedule.Fri.length === 0
                                ? "-"
                                : getVacancyData.vacancy_schedule.Fri[0] +
                                " hrs"
                              }}
                            </td>

                            <td class="caption text-left d-flex align-center justify-center"
                              style="border-bottom: 1px solid #11203a29">
                              {{
                                getVacancyData.vacancy_schedule.Sat.length === 0
                                ? "-"
                                : getVacancyData.vacancy_schedule.Sat[0] +
                                " hrs"
                              }}
                            </td>

                            <td class="caption text-left d-flex align-center justify-center">
                              {{
                                getVacancyData.vacancy_schedule.Sun.length === 0
                                ? "-"
                                : getVacancyData.vacancy_schedule.Sun[0] +
                                " hrs"
                              }}
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </div>
                  <div class="mr-10">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn color="#F86E80" fab depressed x-small dark v-bind="attrs" v-on="on" v-if="!editing"
                          @click="editDisponib()"><v-icon>mdi-pencil-box-outline</v-icon></v-btn>
                      </template>
                      <span>Editar disponibilidad</span>
                    </v-tooltip>
                    <v-dialog v-model="dialogEditDays" width="700">
                      <v-card class="pa-3" elevation="0">
                        <v-card-title class="d-flex justify-space-between">
                          <p class="text-body-1 ma-0 font-weight-bold">
                            Editar disponibilidad
                          </p>
                          <v-btn icon @click="dialogEditDays = false"><v-icon>mdi-close-circle-outline</v-icon></v-btn>
                        </v-card-title>

                        <v-card class="d-flex justify-space-between pa-0" elevation="0">
                          <v-card width="50%" class="d-flex justify-center align-center" elevation="0">
                            <div class="flex-wrap" style="width: 100%">
                              <v-btn-toggle v-model="weekdayAppointmentsSchedule" class="flex-wrap justify-center">
                                <v-btn value="Mon" color="#6200EE" dark class="ml-2 px-1 caption text-capitalize mb-3"
                                  style="border-radius: 25px" @click="getHoursIfExist('Mon')">Lun</v-btn>
                                <v-btn value="Tue" color="#6200EE" dark class="ml-2 px-1 caption text-capitalize mb-3"
                                  style="border-radius: 25px" @click="getHoursIfExist('Tue')">Mar</v-btn>
                                <v-btn value="Wed" color="#6200EE" dark class="ml-2 px-1 caption text-capitalize mb-3"
                                  style="border-radius: 25px" @click="getHoursIfExist('Wed')">Mie</v-btn>
                                <v-btn value="Thu" color="#6200EE" dark class="ml-2 px-1 caption text-capitalize"
                                  style="border-radius: 25px" @click="getHoursIfExist('Thu')">Jue</v-btn>
                                <v-btn value="Fri" color="#6200EE" dark class="ml-2 px-1 caption text-capitalize"
                                  style="border-radius: 25px" @click="getHoursIfExist('Fri')">Vie</v-btn>
                                <v-btn value="Sat" color="#6200EE" dark class="ml-2 px-1 caption text-capitalize"
                                  style="border-radius: 25px" @click="getHoursIfExist('Sat')">Sab</v-btn>
                                <v-btn value="Sun" color="#6200EE" dark class="ml-2 px-1 caption text-capitalize"
                                  style="border-radius: 25px" @click="getHoursIfExist('Sun')">Dom</v-btn>
                              </v-btn-toggle>
                            </div>
                          </v-card>
                          <v-divider class="mx-4" vertical></v-divider>
                          <v-card class="" width="50%" elevation="0">
                            <v-form ref="formSelectHourEdit" v-model="valid" lazy-validation class="d-flex flex-column">
                              <v-col cols="12">
                                <v-menu ref="menu" :nudge-right="40" transition="scale-transition" offset-y
                                  :return-value.sync="time" :close-on-content-click="false" v-model="menuStartHour">
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-text-field readonly v-bind="attrs" v-on="on" color="#8739EB" class="caption"
                                      v-model="startHourPicker" :rules="[
                                        (v) => !!v || 'Este campo es requerido',
                                      ]">
                                      <template v-slot:label class="caption">
                                        <v-icon class="icon-input" small>
                                          mdi-calendar-start
                                        </v-icon>
                                        <span class="caption ml-2">Hora de inicio</span>
                                      </template>
                                    </v-text-field>
                                  </template>
                                  <v-time-picker v-model="startHourPicker" :max="endHourPicker" format="24hr"
                                    color="#6200EE" @click:minute="$refs.menu.save(time)"
                                    :landscape="$vuetify.breakpoint.smAndUp" header-color="#A4A1FB"></v-time-picker>
                                </v-menu>
                              </v-col>
                              <v-col cols="12">
                                <v-menu ref="menu2" :nudge-right="40" transition="scale-transition" offset-y
                                  :return-value.sync="time" :close-on-content-click="false" v-model="menuEndHour">
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-text-field readonly v-bind="attrs" v-on="on" color="#8739EB" class="caption"
                                      v-model="endHourPicker" :rules="[
                                        (v) => !!v || 'Este campo es requerido',
                                      ]">
                                      <template v-slot:label class="caption">
                                        <v-icon class="icon-input" small>
                                          mdi-calendar-end
                                        </v-icon>
                                        <span class="caption ml-2">Hora de término</span>
                                      </template>
                                    </v-text-field>
                                  </template>
                                  <v-time-picker v-model="endHourPicker" :min="startHourPicker" format="24hr"
                                    @click:minute="$refs.menu2.save(time)" color="#6200EE"
                                    :landscape="$vuetify.breakpoint.smAndUp" header-color="#A4A1FB"></v-time-picker>
                                </v-menu>

                                <!-- botones -->
                                <v-col cols="12" class="d-flex align-center justify-end mt-4">
                                  <v-btn :disabled="!valid" ref="hour" class="ma-0 pa-2" color="#6200EE" rounded x-small
                                    dark @click="addHourRange()">
                                    <v-icon small>mdi-plus-circle-outline</v-icon>
                                    <p class="ma-0 pa-1 text-capitalize text-white">
                                      Agregar
                                    </p>
                                  </v-btn>
                                </v-col>
                                <!-- <v-progress-circular color="#A4A1FB" size="20" width="2" indeterminate></v-progress-circular> -->
                              </v-col>
                              <!-- show -->

                              <div class="d-flex flex-wrap align-center justify-center align-center" style="width: 100%">
                                <div class="d-flex align-start justify-start" v-for="(
                                    hourRange, index
                                  ) in selectedHourRanges" :key="index">
                                  <!-- eslint-disable vue/no-use-v-if-with-v-for,vue/no-confusing-v-for-v-if -->
                                  <v-chip small close @click:close="remove(index)" v-if="chip" color="#6200EE"
                                    class="white--text caption mr-2 mt-3">{{ hourRange.start_hour }} -
                                    {{ hourRange.end_hour }}</v-chip>
                                </div>
                              </div>
                            </v-form>
                          </v-card>
                        </v-card>

                        <v-card-actions class="mt-16 mb-4">
                          <v-spacer></v-spacer>
                          <v-btn rounded small elevation="0" color="#E3E9EF9E" style="color: #a4a5ad"
                            class="pl-8 pr-8 text-capitalize" @click="(dialogEditDays = false), resetHour()">
                            Cancelar
                          </v-btn>
                          <v-btn :disabled="!selectHour" rounded small elevation="0" color="#43425D" style="color: #fff"
                            class="pl-8 pr-8 mr-5 text-capitalize" @click="saveHoursSchedule()">
                            Listo
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </div>
                </div>
              </div>
              <v-divider></v-divider>
              <!-- End of first part of the form -->
            </v-card>
          </v-flex>
          <v-flex d-flex justify-start xs12 sm12 md6>
            <v-card class="d-flex flex-column" width="95%" elevation="0">
              <div class="d-flex align-center mt-5 mb-5">
                <div class="d-flex align-center" style="width: 50%">
                  <v-icon color="#8739EB" small>mdi-clock-outline</v-icon>
                  <p class="caption ma-0 font-weight-bold ml-6 mr-16">
                    Tipo de horario
                  </p>
                </div>
                <v-text-field :rules="[(v) => !!v || 'Este campo es requerido']" :readonly="!editing"
                  v-model="jobShiftType" class="d-flex ma-0 text-left font-weight-light mr-8 mt-n4 body-1 py-6"
                  color="#8739EB" dense rounded :filled="editing">
                </v-text-field>
              </div>
              <v-divider></v-divider>
              <div class="d-flex align-center mt-5 mb-5">
                <div class="d-flex align-center" style="width: 50%">
                  <v-icon color="#8739EB" small>mdi-school-outline</v-icon>
                  <p class="caption ma-0 font-weight-bold ml-6 mr-16 text-left">
                    Estudios mínimos requeridos
                  </p>
                </div>
                <v-select :items="degrees" v-model="minimumLevelEducation" :filled="editing" rounded hide-details
                  class="mr-8 mt-n8 mb-n8 caption text-left font-weight-light" :readonly="!editing" dense append-icon="">
                </v-select>
              </div>
              <v-divider></v-divider>
              <div class="d-flex align-center mt-5 mb-5">
                <div class="d-flex align-center" style="width: 50%">
                  <v-icon color="#8739EB" small>mdi-earth</v-icon>
                  <p class="caption ma-0 font-weight-bold ml-6 mr-16">País</p>
                </div>
                <v-text-field :readonly="!editing" v-model="vacancyCountry"
                  :rules="[(v) => !!v || 'Este campo es requerido']"
                  class="d-flex ma-0 text-left font-weight-light mr-8 mt-n4 body-1 py-6" color="#8739EB" dense rounded
                  :filled="editing">
                </v-text-field>
              </div>
              <v-divider></v-divider>
              <div class="d-flex align-center mt-5 mb-5">
                <div class="d-flex align-center" style="width: 50%">
                  <v-icon color="#8739EB" small>mdi-city-variant-outline</v-icon>
                  <p class="caption ma-0 font-weight-bold ml-6 mr-16">Ciudad</p>
                </div>
                <v-text-field :readonly="!editing" v-model="vacancyCity"
                  :rules="[(v) => !!v || 'Este campo es requerido']"
                  class="d-flex ma-0 text-left font-weight-light mr-8 mt-n4 body-1 py-6" color="#8739EB" dense rounded
                  :filled="editing">
                </v-text-field>
              </div>
              <v-divider></v-divider>
              <div class="d-flex align-center mt-5 mb-5">
                <div class="d-flex align-center" style="width: 50%">
                  <v-icon color="#8739EB" small>mdi-numeric</v-icon>
                  <p class="caption ma-0 text-left font-weight-bold ml-6 mr-16">
                    Código Postal
                  </p>
                </div>
                <v-text-field :readonly="!editing" v-model="vacancyZipCode"
                  :rules="[(v) => !!v || 'Este campo es requerido']"
                  class="d-flex ma-0 text-left font-weight-light mr-8 mt-n4 body-1 py-6" color="#8739EB" dense rounded
                  :filled="editing">
                </v-text-field>
              </div>
              <v-divider></v-divider>
              <div style="width: 100%" class="d-flex justify-start">
                <p class="text-body-1 ma-0 font-weight-bold mt-5 mb-5">
                  Dirección de la entrevista
                </p>
              </div>
              <v-card class="cont-nv" elevation="0" width="95%">
                <div class="d-flex align-center mt-5 mb-5">
                  <div class="d-flex align-center" style="width: 50%">
                    <v-icon color="#8739EB" small>mdi-map-marker-outline</v-icon>
                    <p :readonly="editing" class="caption ma-0 font-weight-bold ml-6 mr-16">
                      Calle
                    </p>
                  </div>
                  <v-text-field :readonly="!editing" v-model="interviewStreet"
                    :rules="[(v) => !!v || 'Este campo es requerido']"
                    class="d-flex ma-0 text-left font-weight-light mr-8 mt-n4 py-6" color="#8739EB" dense rounded
                    :filled="editing">
                  </v-text-field>
                </div>
                <v-divider></v-divider>
                <div class="d-flex align-center mt-5 mb-5">
                  <div class="d-flex align-center" style="width: 50%">
                    <v-icon color="#8739EB" small>mdi-numeric</v-icon>
                    <p class="caption ma-0 font-weight-bold ml-6 mr-16">
                      Número
                    </p>
                  </div>
                  <v-text-field :readonly="!editing" v-model="interviewExternalNumber"
                    :rules="[(v) => !!v || 'Este campo es requerido']"
                    class="d-flex ma-0 text-left font-weight-light mr-8 mt-n4 py-6" color="#8739EB" dense rounded
                    :filled="editing">
                  </v-text-field>
                </div>
                <v-divider></v-divider>
                <div class="d-flex align-center mt-5 mb-5">
                  <div class="d-flex align-center" style="width: 50%">
                    <v-icon color="#8739EB" small>mdi-numeric</v-icon>
                    <p class="caption ma-0 font-weight-bold text-left ml-6 mr-16">
                      Número interior
                    </p>
                  </div>
                  <v-text-field :readonly="!editing" v-model="interviewInternalNumber"
                    :rules="[(v) => !!v || 'Este campo es requerido']"
                    class="d-flex ma-0 text-left font-weight-light mr-8 mt-n4 py-6" color="#8739EB" dense rounded
                    :filled="editing">
                  </v-text-field>
                </div>
                <v-divider></v-divider>
                <div class="d-flex align-center mt-5 mb-5">
                  <div class="d-flex align-center" style="width: 50%">
                    <v-icon color="#8739EB" small>mdi-map-marker-outline</v-icon>
                    <p class="caption ma-0 font-weight-bold ml-6 mr-16">
                      Colonia
                    </p>
                  </div>
                  <v-text-field :readonly="!editing" v-model="interviewNeighborhood"
                    :rules="[(v) => !!v || 'Este campo es requerido']"
                    class="d-flex ma-0 text-left font-weight-light mr-8 mt-n4 py-6" color="#8739EB" dense rounded
                    :filled="editing">
                  </v-text-field>
                </div>
                <v-divider></v-divider>
                <div class="d-flex align-center mt-5 mb-5">
                  <div class="d-flex align-center" style="width: 50%">
                    <v-icon color="#8739EB" small>mdi-earth</v-icon>
                    <p class="caption ma-0 font-weight-bold ml-6 mr-16">País</p>
                  </div>
                  <v-text-field :readonly="!editing" v-model="interviewCountry"
                    :rules="[(v) => !!v || 'Este campo es requerido']"
                    class="d-flex ma-0 text-left font-weight-light mr-8 mt-n4 py-6" color="#8739EB" dense rounded
                    :filled="editing">
                  </v-text-field>
                </div>
                <v-divider></v-divider>
                <div class="d-flex align-center mt-5 mb-5">
                  <div class="d-flex align-center" style="width: 50%">
                    <v-icon color="#8739EB" small>mdi-city-variant-outline</v-icon>
                    <p class="caption ma-0 font-weight-bold ml-6 mr-16">
                      Ciudad
                    </p>
                  </div>
                  <v-text-field :readonly="!editing" v-model="interviewCity"
                    :rules="[(v) => !!v || 'Este campo es requerido']"
                    class="d-flex ma-0 text-left font-weight-light mr-8 mt-n4 py-6" color="#8739EB" dense rounded
                    :filled="editing">
                  </v-text-field>
                </div>
                <v-divider></v-divider>
                <div class="d-flex align-center mt-5 mb-5">
                  <div class="d-flex align-center" style="width: 50%">
                    <v-icon color="#8739EB" small>mdi-numeric</v-icon>
                    <p class="caption ma-0 font-weight-bold ml-6 mr-16">
                      Código Postal
                    </p>
                  </div>
                  <v-text-field :readonly="!editing" v-model="interviewZipCode"
                    :rules="[(v) => !!v || 'Este campo es requerido']"
                    class="d-flex ma-0 text-left font-weight-light mr-8 mt-n4 py-6" color="#8739EB" dense rounded
                    :filled="editing">
                  </v-text-field>
                </div>
                <v-divider></v-divider>
                <div class="d-flex align-center mt-5 mb-5">
                  <div class="d-flex align-center" style="width: 50%">
                    <v-icon color="#8739EB" small>mdi-link</v-icon>
                    <p class="caption ma-0 font-weight-bold text-left ml-6 mr-16">
                      Enlace de reunión
                    </p>
                  </div>
                  <v-text-field :readonly="!editing" v-model="interviewURL"
                    :rules="[(v) => !!v || 'Este campo es requerido']"
                    class="d-flex ma-0 text-left font-weight-light mr-8 mt-n4 body-1 py-6" color="#8739EB" dense rounded
                    :filled="editing">
                  </v-text-field>
                </div>
                <v-divider></v-divider>
                <div class="d-flex align-center mt-5 mb-5">
                  <div class="d-flex align-center" style="width: 50%">
                    <v-icon color="#8739EB" small>mdi-phone</v-icon>
                    <p class="caption ma-0 font-weight-bold text-left ml-6 mr-16">
                      Teléfono de contacto
                    </p>
                  </div>
                  <v-text-field :readonly="!editing" v-model="contactPhoneNumber"
                    :rules="[(v) => !!v || 'Este campo es requerido']"
                    class="d-flex ma-0 text-left font-weight-light mr-8 mt-n4 body-1 py-6" color="#8739EB" dense rounded
                    :filled="editing">
                  </v-text-field>
                </div>
                <v-divider></v-divider>
              </v-card>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
      <v-alert dense text type="success" transition="scale-transition" class="mt-2" v-if="editScheduleD">
        <p class="ma-0 pa-0 caption">¡Horario modificado con éxito!</p>
      </v-alert>
    </v-card>
    <v-snackbar v-model="alert" timeout="3000">
      {{ alertText }}

      <template v-slot:action="{ attrs }">
        <v-btn color="#6200EE" text v-bind="attrs" @click="alert = false">
          Ok
        </v-btn>
      </template>
    </v-snackbar>
    <div v-if="vacanciesStateLoading === true">
      <transition name="modal-fade">
        <div class="modal-backdrop">
          <div class="modal" role="dialog" area-labelledby="modalTitle" aria-describedby="modalDesc">
            <v-col cols="10">
              <h4 class="mb-4 font-weight-regular">espere un momento...</h4>
              <v-progress-linear color="#6E80F8" indeterminate rounded height="6"></v-progress-linear>
            </v-col>
          </div>
        </div>
      </transition>
    </div>
  </v-card>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import format from "date-fns/format";
import parseISO from "date-fns/parseISO";
import ENUM from "@/store/enums";
import { getGlobal } from "@firebase/util";
import moment from "moment";

export default {
  components: {},
  name: "Settings",
  data: () => ({
    dialogEditDays: false,
    editing: false,
    valid: false,
    menuStartHour: false,
    menuEndHour: false,
    selectHour: false,
    alert: false,
    alertText: "",
    chip: true,
    startHourPicker: "",
    endHourPicker: "",
    vacancyId: "",
    vacancyName: "",
    vacancyDescription: "",
    vacancyJobHours: "",
    avaliableVacancies: "",
    companyArea: "",
    vacancyStartsAt: "",
    vacancyExpiresAt: "",
    jobShiftType: "",
    minimumLevelEducation: "",
    vacancyCountry: "",
    vacancyCity: "",
    vacancyZipCode: "",
    interviewStreet: "",
    interviewExternalNumber: "",
    interviewInternalNumber: "",
    interviewNeighborhood: "",
    interviewCountry: "",
    interviewCity: "",
    interviewZipCode: "",
    interviewURL: "",
    contactPhoneNumber: "",
    calendarPickerDateExpires: "",
    calendarPickerDateStarts: "",
    vacancyMaximumCandidates: "",
    aplications: "",
    weekdayAppointmentsSchedule: null,
    time: null,
    vacancy_schedule: {
      Mon: [],
      Tue: [],
      Wed: [],
      Thu: [],
      Fri: [],
      Sat: [],
      Sun: [],
    },
    selectedHourRanges: [],
    week: [
      { value: 0, text: "Lunes" },
      { value: 1, text: "Martes" },
      { value: 2, text: "Miércoles" },
      { value: 3, text: "Jueves" },
      { value: 4, text: "Viernes" },
      { value: 5, text: "Sábado" },
      { value: 6, text: "Domingo" },
    ],
    degrees: [
      { value: 0, text: "Sin estudios" },
      { value: 1, text: "Primaria Trunca" },
      { value: 2, text: "Primaria terminada" },
      { value: 3, text: "Secundaria trunca" },
      { value: 4, text: "Secundaria terminada" },
      { value: 5, text: "Preparatoria trunca" },
      { value: 6, text: "Preparatoria terminada" },
      { value: 7, text: "Universidad trunca" },
      { value: 8, text: "Universidad terminada" },
      { value: 9, text: "Primaria Trunca" },
      { value: 10, text: "Maestria trunca" },
      { value: 11, text: "Maestria" },
    ],
  }),
  computed: {
    ...mapGetters(["getVacancyData", "getAllCandidates", "getHouranDay"]),
    ...mapState({
      companyIdFromStore: (state) => state.auth.user.user_company,
      selectedZone: (state) => state.vacancies.selectedZone,
      candidatesState: (state) => state.candidates,
      vacanciesState: (state) => state.vacancies.status,
    }),
    vacanciesStateLoading() {
      return this.vacanciesState === ENUM.LOADING;
    },
    daySel() {
      return this.getHouranDay;
    },
    noData() {
      return "Sin datos";
    },
    formattedDateEnds() {
      // Here we have to notice that the library used changed and today doesn't have support for strings
      return this.calendarPickerDateExpires
        ? moment(calendarPickerDateExpires).format("DD/MM/YYYY")
        : this.vacancyExpiresAt;
    },
    formattedDateStarts() {
      // Here we have to notice that the library used changed and today doesn't have support for strings
      return this.calendarPickerDateStarts
        ? format(parseISO(this.calendarPickerDateStarts), "do MMM yyyy")
        : this.vacancyStartsAt;
    },
    editedVacancy() {
      return this.$store.state.vacancies.editVacancy;
    },
    editScheduleD() {
      return this.$store.state.vacancies.editSchedule;
    },
  },
  methods: {
    ...mapActions([
      "requestVacancyById",
      "updateVacancyScheduleById",
      "requestsAllCandidates",
      "updateVacancyById",
    ]),
    formatDate(date) {
      const dateArray = date.substr(date.indexOf(" ") + 1).split(" ");
      return dateArray[2] + "-" + dateArray[0] + "-" + dateArray[1];
    },
    editDisponib() {
      this.dialogEditDays = true;
    },
    async getHoursIfExist(dayKey) {
      // console.log(dayKey, "dayk");
      this.selectedHourRanges = this.vacancy_schedule[dayKey];
    },
    addHourRange() {
      const isValid = this.$refs.formSelectHourEdit.validate();
      if (isValid) {
        if (this.startHourPicker === "" || this.endHourPicker === "") {
          this.alertText =
            "Debes llenar ambos campos para añadir el rango de horas";
          this.alert = true;
        } else {
          const newRange = {
            start_hour: this.startHourPicker,
            end_hour: this.endHourPicker,
          };
          this.selectedHourRanges.push(newRange);
          this.selectHour = true;
          this.resetHour();
        }
      } else {
        this.alertText =
          "Debes llenar ambos campos para añadir el rango de horas";
        this.alert = true;
      }
    },
    resetHour() {
      this.$refs.formSelectHourEdit.reset();
    },
    saveHoursSchedule() {
      this.dialogEditDays = false;
      if ((this.selectHour = true)) {
        this.resetHour();
        this.vacancy_schedule[this.weekdayAppointmentsSchedule] =
          this.selectedHourRanges;
        // console.log(this.vacancy_schedule, "schedule");
        // console.log(this.selectedHourRanges[0], "ranges");
        const payload = {
          company_id: this.$store.state.auth.user.user_company,
          company_zone_id: this.$store.state.vacancies.selectedZone,
          vacancy_id: this.$store.state.candidates.selectedVacancy,
          vacancy_schedule: this.vacancy_schedule,
        };
        // console.log(payload, "payload edit");
        this.updateVacancyScheduleById(payload);
      } else {
        this.alertText = "Selecciona un horario";
        this.alert = true;
      }
    },
    remove(index) {
      let idx = this.selectedHourRanges.indexOf(index);
      this.selectedHourRanges.splice(idx, 1);
      this.selectedHourRanges = [...this.selectedHourRanges];
    },
    logUpdating() {

      const ruta = this.vacancyId;
      let payload;
      // console.log(this.vacancyName, "this vacancies name")

      if (ruta === "" || this.vacancyJobHours === "" || this.jobShiftType === "" || this.vacancyCity === "" || !this.getVacancyData.vacancy_scheduled_appointments[0] || this.companyIdFromStore === "" || this.vacancyExpiresAt === "" || this.getVacancyData.vacancy_status === "" || this.vacancyName === "" || this.vacancyDescription === "" || this.avaliableVacancies === "" || this.companyArea === "" || this.formattedDateStarts === "" || this.vacancyMaximumCandidates === "" || this.vacancyCountry === "" || this.vacancyZipCode === "" || this.interviewStreet === "" || this.interviewExternalNumber === "" || this.interviewInternalNumber === "" || this.interviewNeighborhood === "" || this.interviewCountry === "" || this.interviewCity === "" || this.interviewZipCode === "" || this.interviewURL === "" || this.contactPhoneNumber === "") {

        console.log("no avanzar")
      } else {
        console.log(this.vacancyName.length)
        if (this.vacancyName.length < 20 && this.vacancyJobHours.length <= 3950) {
          payload = {
            vacancy_id: ruta,
            vacancy_job_hours: this.vacancyJobHours,
            vacancy_job_shift_type: this.jobShiftType,
            vacancy_city: this.vacancyCity,
            vacancy_scheduled_appointments: [
              this.getVacancyData.vacancy_scheduled_appointments[0],
            ],
            company_id: this.companyIdFromStore,
            vacancy_expires_at: this.vacancyExpiresAt,
            vacancyStatus: this.getVacancyData.vacancy_status,
            vacancy_schedule: {
              Sun: this.getVacancyData.vacancy_schedule.Sun,
              Mon: this.getVacancyData.vacancy_schedule.Mon,
              Tue: this.getVacancyData.vacancy_schedule.Tue,
              Wed: this.getVacancyData.vacancy_schedule.Wed,
              Thu: this.getVacancyData.vacancy_schedule.Thu,
              Fri: this.getVacancyData.vacancy_schedule.Fri,
              Sat: this.getVacancyData.vacancy_schedule.Sat,
            },
            company_zone_id: this.selectedZone,
            vacancy_starts_at: this.vacancyStartsAt,
            vacancy_minimum_level_of_education_required: this.minimumLevelEducation,
            vacancy_country: this.vacancyCountry,
            vacancy_zip_code: this.vacancyZipCode,
            vacancy_created_by: this.getVacancyData.vacancy_created_by,
            number_of_available_vacancies: this.avaliableVacancies,
            vacancy_company_area: this.companyArea,
            vacancy_name: this.vacancyName,
            vacancy_appointments_info: {
              last_appointment:
                this.getVacancyData.vacancy_appointments_info.last_appointment,
              last_appointment_occupancy:
                this.getVacancyData.vacancy_appointments_info
                  .last_appointment_occupancy,
              max_occupancy:
                this.getVacancyData.vacancy_appointments_info.max_occupancy,
            },
            interview_address: {
              physical_interview_address: {
                interview_vacancy_street: this.interviewStreet,
                interview_vacancy_street_number: this.interviewExternalNumber,
                interview_vacancy_interior_number: this.interviewInternalNumber,
                interview_vacancy_neighborhood: this.interviewNeighborhood,
                interview_vacancy_country: this.interviewCountry,
                interview_vacancy_city: this.interviewCity,
                interview_vacancy_zip_code: this.interviewZipCode,
              },
              remote_interview_url: this.interviewURL,
              contact_phone_number: this.contactPhoneNumber,
            },
            vacancy_description: this.vacancyDescription,
          };
          // console.log(payload, "payload")
          this.editing = false
          this.updateVacancyById(payload);
        } else {
          console.log("mostrar que no")
          // this.editing = true
        }
      }

    },
  },
  mounted() {
    this.requestVacancyById(this.$route.params.id);
    this.vacancyId = this.$route.params.id;
    this.requestsAllCandidates(this.$route.params.id);
  },
  watch: {
    editedVacancy() {
      setTimeout(() => this.$store.commit("ALERT_EDIT_VACANCY", false), 3000);
    },
    editScheduleD() {
      setTimeout(
        () => this.$store.commit("EDITED_SCHEDULE_ALERT", false),
        3000
      );
    },
    getAllCandidates() {
      this.aplications = this.getAllCandidates.length;
    },
    getVacancyData() {
      this.vacancy_schedule = this.vacancy_schedule;
      this.vacancyName = this.getVacancyData.vacancy_name;
      this.vacancyDescription = this.getVacancyData.vacancy_description;
      this.vacancyJobHours = this.getVacancyData.vacancy_job_hours;
      this.avaliableVacancies =
        this.getVacancyData.number_of_available_vacancies;
      this.companyArea = this.getVacancyData.vacancy_company_area;
      this.vacancyStartsAt = new Date(this.getVacancyData.vacancy_starts_at)
        .toISOString()
        .split("T")[0];
      this.vacancyExpiresAt = new Date(this.getVacancyData.vacancy_expires_at)
        .toISOString()
        .split("T")[0];
      this.jobShiftType = this.getVacancyData.vacancy_job_shift_type;
      this.minimumLevelEducation =
        this.getVacancyData.vacancy_minimum_level_of_education_required;
      this.vacancyCountry = this.getVacancyData.vacancy_country;
      this.vacancyCity = this.getVacancyData.vacancy_city;
      this.vacancyZipCode = this.getVacancyData.vacancy_zip_code;
      this.interviewStreet =
        this.getVacancyData.interview_address.physical_interview_address.interview_vacancy_street;
      this.interviewExternalNumber =
        this.getVacancyData.interview_address.physical_interview_address.interview_vacancy_street_number;
      this.interviewInternalNumber =
        this.getVacancyData.interview_address.physical_interview_address.interview_vacancy_interior_number;
      this.interviewNeighborhood =
        this.getVacancyData.interview_address.physical_interview_address.interview_vacancy_neighborhood;
      this.interviewCountry =
        this.getVacancyData.interview_address.physical_interview_address.interview_vacancy_country;
      this.interviewCity =
        this.getVacancyData.interview_address.physical_interview_address.interview_vacancy_city;
      this.interviewZipCode =
        this.getVacancyData.interview_address.physical_interview_address.interview_vacancy_zip_code;
      this.interviewURL =
        this.getVacancyData.interview_address.remote_interview_url;
      this.contactPhoneNumber =
        this.getVacancyData.interview_address.contact_phone_number;
      this.vacancyMaximumCandidates =
        this.getVacancyData.vacancy_maximum_candidates;
    },
  },
};
</script>

<style>
.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  width: 25vw;
  height: 20vh;
  display: flex;
  justify-content: center;
  align-items: center !important;
  background: transparent;
  box-shadow: 0;
  overflow: hidden;
}

.modal-fade-enter,
.modal-fade-leave-active {
  opacity: 0;
}

.modal-fade-enter-active,
modal-fade-leave-active {
  transition: opacity 0.5s ease;
}

h4 {
  color: #fff;
  letter-spacing: 2px;
}

.v-text-field.v-input--dense:not(.v-text-field--outlined) .v-text-field__prefix,
.v-text-field.v-input--dense:not(.v-text-field--outlined) .v-text-field__suffix,
.v-text-field.v-input--dense:not(.v-text-field--outlined) input {
  padding: 4px 0 2px;
  font-size: 12px !important;
}

.v-select.v-input--dense .v-select__selection--comma {
  margin: 5px 4px 3px 0;
  font-size: 12px !important;
}
</style>
