<template>
  <div>
    <v-dialog v-model="dialog" persistent max-width="880">
      <template v-slot:activator="{ on, attrs }">
        <v-card elevation="0">
          <v-card-title class="d-flex align-center mt-5 mb-6">
            <p class="text-body-1 ma-0 font-weight-bold mr-8">
              Gestiona tus candidatos
            </p>
            <v-btn small class="text-none caption" rounded outlined color="#8739EB" elevation="0" v-bind="attrs"
              v-on="on">
              <v-icon class="mr-1" small color="#8739EB">mdi-plus-circle-outline</v-icon>
              Crear Candidato
              <div v-if="candidatesStateLoading === true">
                <transition name="modal-fade">
                  <div class="modal-backdrop">
                    <div class="modal" role="dialog" area-labelledby="modalTitle" aria-describedby="modalDesc">
                      <v-col cols="10">
                        <h4 class="mb-4 font-weight-regular">
                          espere un momento...
                        </h4>
                        <v-progress-linear color="#6E80F8" indeterminate rounded height="6"></v-progress-linear>
                      </v-col>
                    </div>
                  </div>
                </transition>
              </div>
            </v-btn>
            <div class="d-flex justify-end">
              <vue-excel-xlsx :data="filtroAllVacanciesFiltered" :columns="columns" :file-name="'Vacantes'" :file-type="'xlsx'"
                :sheet-name="'sheetname'">
                <v-btn tile color="transparent" elevation="0" class="caption text-capitalize mr-3">
                  <v-icon color="#74DEA4" x-small class="mr-1">mdi-download</v-icon>
                  <p class="pa-0 ma-0" style="color: #74dea4">Descargar XLSX</p>
                </v-btn>
              </vue-excel-xlsx>
            </div>
            <div>


              <!-- <v-select label="Selecciona fecha" :items="['2020', '2021', '2022', '2023']"></v-select> -->

              <v-menu v-model="menu" offset-y :close-on-content-click="false">
                <template v-slot:activator="{ on }">

                  <div class="d-flex caption text-capitalize mr-3 v-btn v-btn--has-bg v-btn--tile theme--light elevation-0 v-size--default transparent" v-on="on" style="cursor: pointer;">
                    <div class="caption text-capitalize mr-3 ms-3"
                      style="width: 100%; color: #8739EB">Filtrar por día</div>
                      <v-icon  color="#8739EB">mdi-calendar</v-icon>
                  </div>

                </template>
                <v-date-picker v-model="picker" @click="menu = false" color="#8739EB" />
              </v-menu>
              <!-- <div>{{ picker }}</div> -->
            </div>
            <div>
              <v-menu v-model="menu1" offset-y :close-on-content-click="false">
                <template v-slot:activator="{ on }">
                  <div class="d-flex caption text-capitalize mr-3 v-btn v-btn--has-bg v-btn--tile theme--light elevation-0 v-size--default transparent" v-on="on" style="cursor: pointer;">
                    <div class="caption text-capitalize mr-3 ms-3"
                      style="width: 100%; color: #8739EB">Filtrar por mes</div>
                    <v-icon  color="#8739EB">mdi-calendar</v-icon>
                  </div>
                </template>
                <div>

                  <v-date-picker v-model="picker" @click="menu1 = false" type="month" color="#8739EB"></v-date-picker>
                </div>
              </v-menu>

            </div>
            <div>
              
              <v-btn @click="picker = ''" tile color="transparent" elevation="0" class="caption text-capitalize mr-3">
                <!-- <v-icon color="#74DEA4" x-small class="mr-1">mdi-download</v-icon> -->
                <p class="pa-0 ma-0" style="color: #74dea4">Mostrar todos los candidatos</p>
              </v-btn>
            </div>
          </v-card-title>
          <div class="my-4">
            <v-text-field v-model="search" color="#6200EE" class="caption shrink ml-2 pl-3 ma-0 pa-0 mb-2 text-capitalize"
              style="width: 100%; background: #f7f8fa" single-line hide-details>
              <template v-slot:label class="caption">
                <span class="caption ml-2">Buscar</span>
              </template>
            </v-text-field>
          </div>
          <!-- <div class="d-flex justify-end">
            <vue-excel-xlsx :data="getAllCandidates" :columns="columns" :file-name="'Candidatos'" :file-type="'xlsx'"
              :sheet-name="'sheetname'">
              <v-btn tile color="transparent" elevation="0" class="caption text-capitalize mr-3">
                <v-icon color="#74DEA4" x-small class="mr-1">mdi-download</v-icon>
                <p class="pa-0 ma-0" style="color: #74dea4">Descargar XLSX</p>
              </v-btn>
            </vue-excel-xlsx>
          </div> -->
        </v-card>
      </template>
      <v-card class="pb-5 pa-4">
        <div class="d-flex justify-start">
          <p class="text-body-1 ma-0 font-weight-bold ml-4 mb-5">
            Crear Candidato
          </p>
        </div>
        <v-container fluid grid-list-md>
          <v-layout row wrap d-flex justify-start>
            <v-flex d-flex flex-column align-start xs12 sm12 md6>
              <v-card class="cont-nv" elevation="0" width="95%">
                <v-text-field style="padding: 3px; height: 30px" color="#6200EE" class="input-nv"
                  v-model="newCandidate.candidate_fullname" :rules="[(v) => !!v || 'Este campo es requerido']"
                  placeholder="ej. Juan" required>
                  <template v-slot:label class="caption">
                    <v-icon class="icon-input" small>
                      mdi-account-outline
                    </v-icon>
                    <span class="caption ml-2">Nombre del candidato</span>
                  </template>
                </v-text-field>

                <v-text-field style="padding: 3px; height: 30px" color="#6200EE" class="input-nv"
                  v-model="newCandidate.candidate_email" :rules="[
                    (v) => !!v || 'Este campo es requerido',

                    (v) =>
                      /.+@.+\..+/.test(v) ||
                      'el email ingresado debe ser valido',
                  ]" placeholder="ej. juanperez@reclutachat.com">
                  <template v-slot:label class="caption">
                    <v-icon class="icon-input" small>
                      mdi-email-outline
                    </v-icon>
                    <span class="caption ml-2">Correo</span>
                  </template>
                </v-text-field>

                <v-text-field style="padding: 3px; height: 30px" color="#6200EE" class="input-nv"
                  v-model="newCandidate.candidate_age" :rules="[(v) => !!v || 'Este campo es requerido']"
                  placeholder="ej. 33">
                  <template v-slot:label class="caption">
                    <v-icon class="icon-input" small> mdi-numeric </v-icon>
                    <span class="caption ml-2">Edad</span>
                  </template>
                </v-text-field>

                <v-select style="padding: 3px; height: 30px" color="#6200EE" class="input-nv"
                  v-model="newCandidate.candidate_availability" :items="Avaliability" menu-props="auto"
                  label="Disponibilidad" single-line :rules="[(v) => !!v || 'Este campo es requerido']">
                  <template v-slot:label class="caption">
                    <v-icon class="icon-input" small>
                      mdi-clock-outline
                    </v-icon>
                    <span class="caption ml-2">Disponibilidad</span>
                  </template>
                  <template v-slot:item="{ item }">
                    <v-list-item-content>
                      <p class="caption">{{ item }}</p>
                      <v-divider></v-divider>
                    </v-list-item-content>
                  </template>
                </v-select>

                <v-text-field style="padding: 3px; height: 30px" color="#6200EE" class="input-nv"
                  v-model="newCandidate.candidate_experience" :rules="[(v) => !!v || 'Este campo es requerido']">
                  <template v-slot:label class="caption">
                    <v-icon class="icon-input" small>
                      mdi-briefcase-check-outline
                    </v-icon>
                    <span class="caption ml-2">Experiencia</span>
                  </template>
                </v-text-field>
                <v-text-field style="padding: 3px; height: 30px" color="#6200EE" class="input-nv"
                  v-model="newCandidate.candidate_license_type" :rules="[(v) => !!v || 'Este campo es requerido']">
                  <template v-slot:label class="caption">
                    <v-icon class="icon-input" small>
                      mdi-card-text-outline
                    </v-icon>
                    <span class="caption ml-2">Tipo de licencia</span>
                  </template>
                </v-text-field>
              </v-card>
            </v-flex>

            <v-flex d-flex flex-column align-start xs12 sm12 md6>
              <v-card class="cont-nv" width="95%" elevation="0">
                <v-text-field style="padding: 3px; height: 30px" color="#6200EE" class="input-nv"
                  v-model="getVacancyData.vacancy_name" disabled :rules="[(v) => !!v || 'Este campo es requerido']">
                  <template v-slot:label class="caption">
                    <v-icon class="icon-input" small>
                      mdi-briefcase-outline
                    </v-icon>
                    <span class="caption ml-2">Vacante de interés</span>
                  </template>
                </v-text-field>

                <v-select style="padding: 3px; height: 30px" color="#6200EE" class="input-nv caption"
                  v-model="newCandidate.candidate_education" :items="school" menu-props="auto" label="Educacion"
                  single-line :rules="[(v) => !!v || 'Este campo es requerido']">
                  <template v-slot:label class="caption">
                    <v-icon class="icon-input" small>
                      mdi-school-outline
                    </v-icon>
                    <span class="caption ml-2">Educacion</span>
                  </template>
                  <template v-slot:item="{ item }">
                    <v-list-item-content>
                      <p class="caption">{{ item.text }}</p>
                      <v-divider></v-divider>
                    </v-list-item-content>
                  </template>
                </v-select>

                <v-text-field style="padding: 3px; height: 30px" color="#6200EE" class="input-nv caption"
                  v-model="newCandidate.candidate_city" :rules="[(v) => !!v || 'Este campo es requerido']">
                  <template v-slot:label class="caption">
                    <v-icon class="icon-input" small>
                      mdi-city-variant-outline
                    </v-icon>
                    <span class="caption ml-2">Ciudad</span>
                  </template>
                </v-text-field>

                <v-text-field style="padding: 3px; height: 30px" color="#6200EE" class="input-nv"
                  v-model="newCandidate.candidate_phone_number" :rules="[(v) => !!v || 'Este campo es requerido']">
                  <template v-slot:label class="caption">
                    <v-icon class="icon-input" small>
                      mdi-phone-outline
                    </v-icon>
                    <span class="caption ml-2">Telefono</span>
                  </template>
                </v-text-field>

                <v-select style="padding: 3px; height: 30px" color="#6200EE" class="input-nv caption"
                  v-model="newCandidate.candidate_status" :items="statusCandidate" menu-props="auto" label="Estatus"
                  single-line :rules="[(v) => !!v || 'Este campo es requerido']">
                  <template v-slot:label class="caption">
                    <v-icon class="icon-input" small> mdi-list-status </v-icon>
                    <span class="caption ml-2">Estatus</span>
                  </template>
                  <template v-slot:item="{ item }">
                    <v-list-item-content>
                      <p class="caption">{{ item.text }}</p>
                      <v-divider></v-divider>
                    </v-list-item-content>
                  </template>
                </v-select>

                <v-select style="padding: 3px; height: 30px" color="#6200EE" class="input-nv caption"
                  v-model="newCandidate.candidate_recruitment_source" :items="recruitment" menu-props="auto"
                  label="Fuente de reclutamiento" single-line :rules="[(v) => !!v || 'Este campo es requerido']">
                  <template v-slot:label class="caption">
                    <v-icon class="icon-input" small> mdi-eye-outline </v-icon>
                    <span class="caption ml-2">Fuente de reclutamiento</span>
                  </template>
                  <template v-slot:item="{ item }">
                    <v-list-item-content>
                      <p class="caption">{{ item.text }}</p>
                      <v-divider></v-divider>
                    </v-list-item-content>
                  </template>
                </v-select>
              </v-card>

              <v-card-actions style="width: 95%" class="mt-5 ma-0 pa-0 d-flex justify-end">
              </v-card-actions>
            </v-flex>
          </v-layout>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn rounded small elevation="0" color="#E3E9EF9E" style="color: #a4a5ad" class="pl-8 pr-8 text-capitalize"
            @click="dialog = false">Cerrar</v-btn>
          <v-btn rounded small elevation="0" color="#43425D" style="color: #fff" class="pl-8 pr-8 mr-5 text-capitalize"
            @click="createCandidateHere(), reload(), (dialog = false)">Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <template> </template>
    <v-alert dense text type="success" transition="scale-transition" v-if="candidateCreated">
      <p class="ma-0 pa-0 caption">¡Candidato registrado con éxito!</p>
    </v-alert>
    <v-alert dense text type="success" transition="scale-transition" v-if="statusChanged">
      <p class="ma-0 pa-0 caption">¡El estatus se modificó con éxito!</p>
    </v-alert>
    <v-card v-if="vacanciesSelected === true" class="d-flex flex-row pa-3" flat tile>
      <v-btn color="#F86E80" fab depressed x-small dark @click="deleteCandidates()">
        <v-icon>mdi-trash-can</v-icon>
      </v-btn>
    </v-card>
    <template>
      <v-data-table v-model="selected" :headers="headers"
        :items="picker !== '' ? filtroAllVacanciesFiltered : allVacanciesFiltered" :hide-default-footer="true"
        :page.sync="page" :items-per-page="itemsPerPage" @page-count="pageCount = $event" item-key="id" show-select
        class="elevation-0">
        <template v-slot:[`item.name`]="{ item }">
          <v-hover v-slot="{ hover }">
            <router-link :to="{ path: 'detail-candidate/' + `${item.id}` }" style="text-decoration: none"
              @click="SELECT_VACANCY(this.$route.params.id)">
              <v-layout justify-start class="font-weight-bold caption" :style="{ color: hover ? '#8739EB' : '#000' }">
                <p class="caption ma-0 pa-0 font-weight-bold text-capitalize">
                  {{ item.name }}
                </p>
              </v-layout>
            </router-link>
          </v-hover>
        </template>
        <template v-slot:[`item.email`]="{ item }">
          <p class="caption ma-0 pa-0 text-lowercase">{{ item.email }}</p>
        </template>
        <template v-slot:[`item.date`]="{ item }">
          <p class="caption ma-0 pa-0">{{ formatDate(item.date) }}</p>
        </template>
        <template v-slot:[`item.phoneNumber`]="{ item }">
          <p class="caption ma-0 pa-0">{{ item.phoneNumber }}</p>
        </template>

        <template v-slot:[`item.status`]="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-chip v-bind="attrs" v-on="on" small :text-color="getTextColor(item.status)"
                :color="getColor(item.status)" @click="getStatus(item.id, item.status)">
                <p class="caption ma-0 pa-0">{{ getText(item.status) }}</p>
              </v-chip>
            </template>
            <span>Cambiar estatus</span>
          </v-tooltip>
        </template>
        <span>Top tooltip</span>
      </v-data-table>
    </template>
    <v-pagination elevation="0" v-model="page" :length="pageCount" class="custom caption mt-7" small color="#A3A1FB"
      circle></v-pagination>
    <v-dialog v-model="statusDialog" persistent max-width="500">
      <v-card elevation="0" height="100%" tile class="pa-5">
        <v-card-title class="d-flex align-center justify-space-between mt-5 mb-5">
          <p class="text-body-1 ma-0 font-weight-bold mr-8">
            Cambiar estatus del candidato
          </p>
          <v-btn icon small class="text-none caption" color="#8739EB" elevation="0" @click="statusDialog = false">
            <v-icon color="#6200EE">mdi-close-circle-outline</v-icon>
          </v-btn>
        </v-card-title>
        <div class="ml-10 mr-10">
          <v-select color="#6200EE" class="input-nv caption" v-model="newStatus" :items="statusCandidate"
            menu-props="auto" label="Estatus" single-line>
            <template v-slot:label class="caption">
              <v-icon class="icon-input" small> mdi-list-status </v-icon>
              <span class="caption ml-2">Estatus</span>
            </template>
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <p class="caption">{{ item.text }}</p>
                <v-divider></v-divider>
              </v-list-item-content>
            </template>
          </v-select>
        </div>
        <v-card-actions class="mt-15">
          <v-spacer></v-spacer>

          <v-btn rounded small elevation="0" color="#E3E9EF9E" style="color: #a4a5ad" class="pl-8 pr-8 text-capitalize"
            @click="statusDialog = false">Cancelar</v-btn>
          <v-btn rounded small elevation="0" color="#43425D" style="color: #fff" class="pl-8 pr-8 mr-5 text-capitalize"
            @click="changeStatus()">Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations, mapState } from "vuex";
import ENUM from "@/store/enums";
import { formatRelativeWithOptions } from "date-fns/fp";
import moment from "moment";

export default {
  data: () => ({
    menu: false,
    menu1: false,
    picker: '',
    picker1: new Date().toISOString().substr(0, 7),
    // landscape: false,
    // picker: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    statusDialog: false,
    search: "",
    newStatus: "",
    idCandidateSelected: "",
    newCandidate: {
      candidate_age: "",
      candidate_availability: "",
      candidate_city: "",
      candidate_education: "",
      candidate_email: "",
      candidate_experience: "",
      candidate_fullname: "",
      candidate_license_type: "",
      candidate_messenger_id: "",
      candidate_phone_number: "",
      candidate_status: "",
      //candidate_company_bmsg_id: "",
      candidate_recruitment_source: "",
      candidate_extra_information: "",
    },
    school: [
      { value: 0, text: "Sin estudios" },
      { value: 1, text: "Primaria Trunca" },
      { value: 2, text: "Primaria terminada" },
      { value: 3, text: "Secundaria trunca" },
      { value: 4, text: "Secundaria terminada" },
      { value: 5, text: "Preparatoria trunca" },
      { value: 6, text: "Preparatoria terminada" },
      { value: 7, text: "Universidad trunca" },
      { value: 8, text: "Universidad terminada" },
      { value: 9, text: "Maestria trunca" },
      { value: 10, text: "Maestria terminada" },
    ],
    statusCandidate: [
      { value: 0, text: "Aceptado" },
      { value: 1, text: "Rechazado" },
      { value: 2, text: "Citado" },
      { value: 3, text: "Acudio a cita" },
      { value: 4, text: "No Acudió a Cita" },
      { value: 5, text: "Contratado" },
      { value: 6, text: "No Contratado" },
    ],
    Avaliability: ["Inmediatamente", "En unas semanas"],
    recruitment: [
      { value: 0, text: "ReclutaChat" },
      { value: 1, text: "OCC" },
      { value: 2, text: "Indeed" },
      { value: 3, text: "Linkedln" },
      { value: 4, text: "Glassdoor" },
      { value: 5, text: "Facebook" },
      { value: 6, text: "Talenteca" },
      { value: 7, text: "Computrabajo" },
      { value: 8, text: "Universidades" },
      { value: 9, text: "Lona" },
      { value: 10, text: "Referidos" },
      { value: 11, text: "Periodico" },
      { value: 12, text: "Volanteo" },
      { value: 13, text: "Vagas (Brasil)" },
      { value: 14, text: "Elempleo (Colombia)" },
      { value: 15, text: "Otro" },
    ],
    columns: [
      {
        label: "Nombre",
        field: "name",
      },
      {
        label: "Fecha",
        field: "date"
      },
      {
        label: "Correo",
        field: "email"
      },
      {
        label: "Teléfono",
        field: "phoneNumber"
      },
      {
        label: "Estado",
        field: "status"
      },
    ],
    dialog: false,
    // search: "",
    // singleSelect: false,
    selected: [],
    //idLink: '',
    headers: [
      { text: "Nombre", align: "start", value: "name", sortable: false },
      { text: "Fecha", value: "date", align: "center" },
      //{ text: "# Candidatos", value: "candidatesNumber" },
      { text: "Correo", value: "email", align: "center" },
      { text: "Teléfono", value: "phoneNumber", align: "center" },
      { text: "Estado", value: "status", align: "center" },
      //{ text: "Interes", value: "status", align: "center" },
      //{ text: "Reclutadores", value: "recruiters" },
    ],
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    chooseDatePicker: false,
  }),

  computed: {
    ...mapGetters(["getAllCandidates", "getVacancyData"]),
    ...mapState({
      candidatesState: (state) => state.candidates.status,
    }),
    
    // filterCandidates(){
    //   let filter = this.getAllCandidates

    //   // if(picker){
    //   //   console.log(picker)
    //   // }
    //   if(this.picker){

    //   }
    //   return filter
    // },
    allVacanciesFiltered() {
      let getAllCandidatesFiltered = this.getAllCandidates
      const input = this.search.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
      if (input) {
        getAllCandidatesFiltered = this.getAllCandidates.filter((item) => {
          const nameItem = item.name.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
          return nameItem.includes(input)
        })
      } else {
        getAllCandidatesFiltered = this.getAllCandidates
      }

      // console.log(getAllCandidatesFiltered , "getAllCandidatesFiltered")
      return getAllCandidatesFiltered
    },

    filtroAllVacanciesFiltered() {
      let getAllCandidatesFiltered = this.getAllCandidates

      // console.log(getAllCandidatesFiltered)
      getAllCandidatesFiltered = this.getAllCandidates.filter((item) => {
        const nameItem = moment(item.date).format("YYYY-MM-DD")
        return nameItem.includes(this.picker)

      })

      // console.log(getAllCandidatesFiltered)
      return getAllCandidatesFiltered
    },
    vacanciesSelected() {
      return this.selected.length > 0 ? true : false;
    },
    candidatesStateLoading() {
      return this.candidatesState === ENUM.LOADING;
    },
    candidateCreated() {
      return this.$store.state.candidates.candidateCreated;
    },
    statusChanged() {
      return this.$store.state.candidates.statusChange;
    },
  },
  methods: {
    ...mapActions([
      "requestsAllCandidates",
      "deleteCandidatesById",
      "createCandidate",
      "updateCandidateStatusById",
    ]),
    ...mapMutations(["SELECT_VACANCY"]),
    createCandidateHere() {
      const payload = {
        candidate_age: this.newCandidate.candidate_age,
        candidate_availability: this.newCandidate.candidate_availability,
        candidate_city: this.newCandidate.candidate_city,
        candidate_education: this.newCandidate.candidate_education,
        candidate_email: this.newCandidate.candidate_email,
        candidate_experience: this.newCandidate.candidate_experience,
        candidate_fullname: this.newCandidate.candidate_fullname,
        candidate_license_type: this.newCandidate.candidate_license_type,
        candidate_messenger_id: "Creado Desde Reclutachat",
        candidate_phone_number: this.newCandidate.candidate_phone_number,
        candidate_status: this.newCandidate.candidate_status,
        candidate_company_bmsg_id: "Creado Desde Reclutachat",
        candidate_recruitment_source:
          this.newCandidate.candidate_recruitment_source,
        candidate_extra_information:
          this.newCandidate.candidate_extra_information,
      };
      this.createCandidate(payload);
    },
    getStatus(id) {
      this.statusDialog = true;
      this.$store.commit("SELECTED_CANDIDATE", id);
    },
    changeStatus() {
      this.statusDialog = false;
      const payload = {
        candidate_company_id: this.$store.state.auth.user.user_company,
        candidate_zone_id: this.$store.state.vacancies.selectedZone,
        candidate_vacancy_id: this.$store.state.candidates.selectedVacancy,
        candidate_id: this.$store.state.candidates.selectedCandidate,
        candidate_status: this.newStatus,
      };
      this.updateCandidateStatusById(payload);
    },
    reload() {
      setTimeout(() => {
        this.requestsAllCandidates(this.$route.params.id);
      }, 2000);
    },
    getText(status) {
      switch (status) {
        case 0:
          return "Aceptado";
        case 1:
          return "Rechazado";
        case 2:
          return "Citado";
        case 3:
          return "Acudio a Cita";
        case 4:
          return "No Acudio a Cita";
        case 5:
          return "Contratado";
        case 6:
          return "No Contratado";
      }
    },
    getColor(status) {
      switch (status) {
        case 0:
          return "#10DC10";
        case 1:
          return "#D40808";
        case 2:
          return "#44BAF5";
        case 3:
          return "#EFF544";
        case 4:
          return "#DA8517";
        case 5:
          return "#6B17DA";
        case 6:
          return "#000000";
      }
    },
    deleteCandidates() {
      const candidatesIds = [];
      this.selected.forEach((candidate) => {
        candidatesIds.push(candidate.id);
      });
      this.deleteCandidatesById(candidatesIds).then(() => {
        this.selected = [];
        setTimeout(() => {
          this.requestsAllCandidates(this.$route.params.id);
        }, 2000);
      });
    },
    getTextColor(status) {
      if (status == 1 || status == 5 || status == 6) return "#FFFFFF";
      else return "#000000";
    },
    formatDate(date) {
      const dateArray = date.substr(date.indexOf(" ") + 1).split(" ");
      return dateArray[1] + " " + dateArray[0] + " " + dateArray[2];
    },
  },
  mounted() {
    this.requestsAllCandidates(this.$route.params.id);
  },
  watch: {
    candidateCreated() {
      return setTimeout(
        () => this.$store.commit("CREATE_CANDIDATE", false),
        3000
      );
    },
    candidateCreated(candidateCreated) {
      if (candidateCreated) {
        return setTimeout(
          () => this.$store.commit("STATUS_CHANGED", false),
          3000
        );
      }
    },
    statusChanged() {
      return setTimeout(
        () => this.$store.commit("STATUS_CHANGED", false),
        3000
      );
    },
  },
};
</script>

<style>
.cta{
  padding: 0 16px;
}
.cta:hover{
  background-color: #f2f3f5;
  
}
.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  width: 25vw;
  height: 20vh;
  display: flex;
  justify-content: center;
  align-items: center !important;
  background: transparent;
  box-shadow: 0;
  overflow: hidden;
}

.cont-nv {
  padding: 0rem !important;
  background-color: #f2f3f5;
}

.input-nv {
  width: 100%;
  margin: 0;
  padding: 0;
  background-color: #fff;
  margin-bottom: 2.3rem;
  font-size: 12px;
}

.modal-fade-enter,
.modal-fade-leave-active {
  opacity: 0;
}

.modal-fade-enter-active,
modal-fade-leave-active {
  transition: opacity 0.5s ease;
}

h4 {
  color: #fff;
  letter-spacing: 2px;
}

.v-application .primary--text {
  color: #6200ee !important;
  caret-color: #6200ee !important;
}
</style>
