<template>
  <v-card class="pr-5 pl-10" elevation="0">
    <v-breadcrumbs
      class="ml-10 ma-0 pa-0 mb-5"
      :items="links"
      divider=">"
      large
    >
      <template v-slot:item="{ item }">
        <v-breadcrumbs-item :href="item.href" :disabled="item.disabled">
          <p class="caption ma-0" style="color: #8739eb; text-decoration: none">
            {{ item.text }}
          </p>
        </v-breadcrumbs-item>
      </template>
    </v-breadcrumbs>
    <v-card-title class="ml-8 mb-3 ma-0 pa-0">
      <div class="d-flex">
        <v-btn icon @click="close"><v-icon small>mdi-arrow-left</v-icon></v-btn>
        <div class="d-flex flex-column align-start mt-1">
          <v-skeleton-loader
            type="list-item"
            class="ma-0 pa-0"
            style="width: 100%"
            :loading="loading"
          >
            <p class="text-body-1 ma-0 text-left font-weight-bold">
              {{ vacancyName }}
            </p>
          </v-skeleton-loader>
          <div class="d-flex align-center">
            <v-skeleton-loader
              small
              type="list-item"
              class="ma-0 pa-0 text-left"
              style="width: 10rem"
              :loading="loading"
            >
              <p class="text-caption ma-0 pa-0">
                {{ companyArea }}
              </p>
            </v-skeleton-loader>
            <v-divider class="mx-2" vertical></v-divider>
            <div class="d-flex align-center">
              <v-skeleton-loader
                type="list-item"
                class="ma-0 pa-0 text-left"
                style="width: 25rem"
                :loading="loading"
              >
                <div class="ma-0 pa-0 d-flex">
                  <v-icon small color="#A3A1FB" class="mr-1"
                    >mdi-map-marker-outline</v-icon
                  >
                  <p class="text-caption ma-0">
                    {{ interviewStreet.lenght === 0 ? "-" : interviewStreet  }} {{ interviewNumber.lenght === 0 ? "-" : interviewNumber }} {{ interviewCountry.lenght === 0 ? "-" : interviewCountry}} {{ interviewCity.lenght === 0 ? "-" : interviewCity }}
                  </p>
                </div>
              </v-skeleton-loader>
            </div>
            <div class="d-flex align-center">
              <v-skeleton-loader
                type="list-item"
                class="ma-0 pa-0 text-left"
                style="width: 9rem"
                :loading="loading"
              >
                <div class="ma-0 pa-0 d-flex">
                  <v-icon small color="#A3A1FB" class="mr-1"
                    >mdi-calendar-start</v-icon
                  >
                  <p class="text-caption ma-0">
                    {{ formatDateCreated(createdAt) }}
                  </p>
                </div>
              </v-skeleton-loader>
            </div>
            <div class="d-flex align-center">
              <v-skeleton-loader
                type="list-item"
                class="ma-0 pa-0 text-left"
                style="width: 25rem"
                :loading="loading"
              >
                <div class="ma-0 pa-0 d-flex">
                  <v-icon small color="#A3A1FB" class="mr-1"
                    >mdi-calendar-end</v-icon
                  >
                  <p class="text-caption ma-0">
                    {{ formatDateEnd(expiresAt) }}
                  </p>
                </div>
              </v-skeleton-loader>
            </div>
            <div class="d-flex ml-16">
              <div class="d-flex align-center mr-10">
                <v-skeleton-loader
                  type="list-item"
                  class="ma-0 pa-0 text-left"
                  style="width: 8rem"
                  :loading="loading"
                >
                  <div class="ma-0 pa-0 d-flex align-center">
                    <p
                      class="text-caption ma-0 font-weight-bold pt-1 pb-1 pl-2 pr-2 rounded mr-2"
                      style="background: #f2f4f7; color: #a4afc0"
                    >
                      {{ availableVacancies }}
                    </p>
                    <p class="text-caption ma-0 font-weight-bold">Vacantes</p>
                  </div>
                </v-skeleton-loader>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-card-title>
    <v-divider></v-divider>
    <v-card class="ml-10 mt-5" elevation="0">
      <v-tabs v-model="tab" grow color="#8739EB" class="d-flex">
        <v-tab class="text-none caption font-weight-bold">
          Detalles de la oferta
        </v-tab>
        <v-tab class="text-none caption font-weight-bold"> Candidatos </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item>
          <GeneralInfo />
        </v-tab-item>
        <v-tab-item>
          <CandidatesTable />
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-card>
</template>

<script>
import GeneralInfo from "../../components/Vacancies/GeneralInfo.vue";
import CandidatesTable from "../../components/Candidates/CandidatesTable.vue";
import ReturnPage from "../../mixins/ReturnPage";
import LoadCard from "../../mixins/LoadCard";
import { mapActions, mapGetters } from "vuex";
import moment from "moment";

export default {
  mixins: [ReturnPage, LoadCard],
  components: {
    GeneralInfo,
    CandidatesTable
  },
  data: () => ({
    links: [
      {
        text: "Vacantes",
        disabled: false,
        href: "/vacancies",
      },
      {
        text: "Detalle",
        disabled: true,
      },
    ],
    vacancyName: '',
    companyArea: '',
    availableVacancies: '',
    interviewStreet: '',
    interviewNumber: '',
    interviewCity: '',
    interviewCountry: '',
    createdAt: '',
    expiresAt: '',
    tab: null,
  }),
  methods: {
    close() {
      this.$router.go(-1);
    },
  },
  computed: {
    ...mapGetters(['getVacancyData']),
  },
  methods: {
    ...mapActions(["requestVacancyById"]),
    ...mapActions(["updateVacancyById"]),
    formatDateCreated(createdAt) {
      return moment(createdAt).format("DD/MM/YYYY");
    },
    formatDateEnd(expiresAt) {
      return moment(expiresAt).format("DD/MM/YYYY");
    },
  },
  created(){
    this.loading
  },
  mounted() {
    this.requestVacancyById(this.$route.params.id);
    this.vacancyId = this.$route.params.id;
  },
  watch:{
    getVacancyData(){
      this.vacancyName = this.getVacancyData.vacancy_name
      this.companyArea = this.getVacancyData.vacancy_company_area
      this.availableVacancies = this.getVacancyData.number_of_available_vacancies
      this.interviewStreet = this.getVacancyData.interview_address.physical_interview_address.interview_vacancy_street
      this.interviewNumber = this.getVacancyData.interview_address.physical_interview_address.interview_vacancy_street_number
      this.interviewCountry = this.getVacancyData.interview_address.physical_interview_address.interview_vacancy_country
      this.interviewCity = this.getVacancyData.interview_address.physical_interview_address.interview_vacancy_city
      this.createdAt = this.getVacancyData.vacancy_starts_at
      this.expiresAt = this.getVacancyData.vacancy_expires_at
    }
  }
};
</script>

<style></style>
